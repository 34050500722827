// Cei Enpoint information (connections and more)

// --- enviroments ---
const version = "2.2.8";


// --- local clusters ---

// 1.- NO WIFI
//const PATH = "http://localhost:3000/api/";
//const BASE_PATH = "http://localhost:3000/";

// 2.- NETWORK
// const PATH = "http://192.168.100.4:3000/api/";
// const BASE_PATH = "http://192.168.100.4:3000/";

// 2.- NETWORK -v
// const PATH = "http://192.168.100.27:3000/api/";
// const BASE_PATH = "http://192.168.100.27:3000/";

// 2.- NETWORK SESVER
// const PATH = "http://10.30.6.177:3000/api/";
// const BASE_PATH = "http://10.30.6.177:3000/";

// --- remote clusters ---

// 1.- xdr testing
// const PATH = "https://cei.bac.edu.mx/demo-apiapi/";
// const BASE_PATH = "https://cei.bac.edu.mx/demo-api";


// --- remote client clusters ---
const PATH = "https://cei.bac.edu.mx/bac-apiapi/";
const BASE_PATH = "https://cei.bac.edu.mx/bac-api";

// --- URL PARA IMPRESION DE REPORTES NUEVOS ---
// const RPT_PATH = "http://localhost:3000/bac/";
const RPT_PATH = "https://xdr.mx/reportes/bac/";

export default {
    PATH,
    BASE_PATH,
    RPT_PATH,
    version,
}